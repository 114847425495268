import Swiper from 'swiper';
import { Navigation, EffectFade} from 'swiper/modules';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import GLightbox from 'glightbox';
import { Fancybox } from "@fancyapps/ui";

// equals tailwind "md:"
const breakpoint = 768;

let swiperOptions = {
    modules: [Navigation, EffectFade],
    navigation: {
        nextEl: '.swiper-button-next-g',
        prevEl: '.swiper-button-prev-g',
    },
    effect: window.innerWidth >= breakpoint ? 'fade' : 'swipe',
    breakpoints: {
        [breakpoint]: {
            crossFade: true,
            speed: 1,
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 0,
        }
    },
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 25,
    centeredSlides: true,
    lazyPreloadPrevNext: 2,
    on: {
        activeIndexChange: function(swiper) {
            let activeSlide = swiper.slides[swiper.activeIndex];
            let prevSlide = swiper.slides[swiper.previousIndex];

            if (activeSlide.dataset.contentType == 'video') {
                let video = activeSlide.querySelector('video');
                video.play();
            }

            if (prevSlide.dataset.contentType == 'video') {
                let video = prevSlide.querySelector('video');
                video.pause();
                video.currentTime = 0;
            }

        },
        resize: function(swiper) {
          // refresh effect property as it cannot be set via "breakpoints"
          swiper.params.effect = window.innerWidth >= breakpoint ? 'fade' : 'swipe';
          // swiper.params.centeredSlides = window.innerWidth >= breakpoint ? 'fade' : 'swipe';
        },
        tap: function(swiper, event) {
          if (swiper.clickedSlide.dataset.contentType != 'image') return;
          // const lb = GLightbox({
          //   elements: [{'href': swiper.clickedSlide.dataset.source}],
          //   autoplayVideos: false,
          //   draggable: true,
          // });
          // lb.open();
          // new Fancybox(swiper.el.dataset.sources.split(',').map(s => {
          //     return {src: s}
          //   }),
          //   {
          //     Thumbs: false,
          //     Hash: false,
          //     Images: {
          //       zoom: true,
          //     },
          //     Toolbar: {
          //       display: {
          //         right: ["close"],
          //       }
          //     }
          //   }
          // );
          Fancybox.show([
            {type: 'image', src: swiper.clickedSlide.dataset.source}
          ],
          {
            Toolbar: {
              display: {
                right: ["close"],
              }
            }
          })
        }
    }
}

window.swiper = new Swiper('.swiper', swiperOptions);

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.start();